* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 100%;
}

/* @media only screen and (max-width: 1200px) {
	html {
		font-size: 80%;
	}
} */

body {
	margin: 0;
	font-family: "Work Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	display: flex;
	flex-flow: column;
}

body button {
	font-family: "Work Sans", sans-serif;
}

body h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b {
	color: #222;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
